import React, { Component } from 'react';
import {
  Label, Popup, Table, Header, Button, Segment, Icon, Form, Input,
  TextArea
} from 'semantic-ui-react';
import moment from "moment";
import APIService from "../API";
import Helpers from "../Helpers"

class TextAlertList extends Component {
  static defaultProps = {
    onSuccess: () => {},
    disableSend: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.snowEmergencyCounty
      && props.snowEmergencyCounty.snowEmergencyMode.id !== state.prevSnowEmergencyModeId
    ) {
      const mode = props.snowEmergencyCounty.snowEmergencyMode;
      const county = props.snowEmergencyCounty;
      switch (mode.name) {
        case 'None':
          state.subject = county.name + " County has no Snow Emergency";
          state.message = county.name + " County has lifted all snow emergencies";
          break;
        default:
          state.subject = county.name + " County has a " + mode.name + " Snow Emergency";
          state.message = "There is a " + mode.name + " snow emergency reported for " + county.name + " County";
          break;
      }
      state.prevSnowEmergencyModeId = mode.id;
      return state
    }
    if (
      props.closing &&
      (props.closing.id !== state.prevClosingId
        || props.closing.updatedAt !== state.prevClosingUpdatedAt)
    ) {
      const {closing} = props;
      const agencyName = closing.agency ? closing.agency.name : closing.unsavedAgencyName;
      const currentDate = moment().format('dddd - MM/DD/YY');
      state.subject = "WMFD.com - " + agencyName + " - " + closing.closingType.name;
      state.message = closing.notes + "\nBTYB: Foundation Academy" + "\nFor " + currentDate;
      state.prevClosingId = closing.id;
      state.prevClosingUpdatedAt = closing.updatedAt;
      return state
    }
    if (
      props.game &&
      (props.game.id !== state.prevGameId
        || props.game.updatedAt !== state.prevGameUpdatedAt)
    ) {
      state.subject = "WMFD Scoreboard";

      let message = "";

      if(props.game.hasOtherPrimarySchool) {
        message += props.game.otherPrimarySchoolName;
      } else {
        message += props.game.primarySchool ? props.game.primarySchool.name : "N/A"
      }
      message += " - ";
      message += (props.game.primarySchoolScore ? props.game.primarySchoolScore : "") + "\n";

      if(props.game.hasOtherSecondarySchool) {
        message += props.game.otherSecondarySchoolName;
      } else {
        message += props.game.secondarySchool ? props.game.secondarySchool.name : "N/A"
      }
      message += " - ";
      message += (props.game.secondarySchoolScore ? props.game.secondarySchoolScore : "") + "\n";

      message +=
        props.game.season.name + "\n" +
        props.game.gameStatus.name + "\n" +
        "BTYB: Joe Behr Heating http://info.wmfd.com/fst";

      state.message = message;
      state.prevGameId = props.game.id
      state.prevGameUpdatedAt = props.game.updatedAt
      return state
    }
  }

  state = {
    subject: "",
    message: "",
    prevSnowEmergencyModeId: undefined,
    prevClosingId: undefined,
    prevClosingUpdatedAt: undefined,
    prevGameId: undefined,
    prevGameUpdatedAt: undefined,
  };

  sendTextAlert = () => {
    fetch(APIService.serverUrl + "/api/private/text-alert", {
      method: 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        ...(this.props.closing ? { closingId: this.props.closing.id } : null),
        ...(this.props.snowEmergencyCounty ? { snowEmergencyCountyId: this.props.snowEmergencyCounty.id } : null),
        ...(this.props.game ? { gameId: this.props.game.id } : null),
        subject: this.state.subject,
        message: this.state.message
      })
    }).then(response => {
      if (response.ok) {
        this.props.onSuccess()
      } else {
        alert("Send text failed: " + response.statusText)
      }
    })
  }

  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render = () => {
    const { textAlerts, disableSend } = this.props;
    let noTexts = textAlerts === undefined || textAlerts.length === 0;

    let sendTextPopupBody =
      <Form style={{minWidth: '400px'}}>
        <Form.Field>
          <label>Subject:</label>
          <Input
            name='subject'
            value={this.state.subject}
            onChange={this.onInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Message:</label>
          <TextArea
            name='message'
            value={this.state.message}
            onChange={this.onInputChange}
          />
        </Form.Field>
        <Form.Field >
          <Button
            size='mini'
            color='green'
            onClick={this.sendTextAlert}
          >
            <Icon name='send' /> Send
          </Button>
        </Form.Field>
      </Form>;

    return (
      <div>
        <Header>
          Sent Text Alerts
          {noTexts ? null :
            <Popup
              hoverable
              wide='very'
              flowing
              basic
              disabled={disableSend}
              trigger={
                <Button
                  style={{padding: '4px 10px', margin: '0px 10px'}}
                  color='green' size='mini' compact
                  content='Send'
                  disabled={disableSend}
                />
              }
              children={sendTextPopupBody}
            />
          }
        </Header>
        {noTexts ?
          // No text alerts
          <Segment placeholder style={{minHeight: '14rem'}} >
            <Header icon>
              <Icon name='send' />
              No texts have been sent
            </Header>
            <Popup
              hoverable
              wide='very'
              flowing
              basic
              disabled={disableSend}
              trigger={
                <Button
                  content='Send Now'
                  color='green' size='mini'
                  disabled={disableSend}
                />
              }
              children={sendTextPopupBody}
            />
          </Segment>
          :
          <Table
            color='blue'
            unstackable
            tableData={textAlerts}
            headerRow={<Table.Row cells={[
              <Table.HeaderCell key='id' content='ID'/>,
              <Table.HeaderCell key='at' content='Sent at'/>,
              <Table.HeaderCell key='me' content='Message' textAlign='center'/>
            ]}/>}
            renderBodyRow={(textAlert) => {
              let success = textAlert.success;
              let error = textAlert.errorResponse;
              let createdAt = moment(textAlert.createdAt);
              let textCasterMessageId = textAlert.textCasterMessageId;
              return (
                <Table.Row key={textAlert.id}>
                  <Table.Cell>
                    {success ?
                      <Label content={textCasterMessageId} />
                      : <Popup content={error} trigger={<Label content='Failed' color='red' />} />
                    }

                  </Table.Cell>
                  <Table.Cell>
                    {createdAt.format('M/DD/YY')}
                    <Label
                      content={createdAt.format('h:mm a')}
                      size='small'
                      style={{
                        marginLeft: '6px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Popup trigger={<Label icon='eye' content='View Content' color='blue' />}>
                      Subject: {textAlert.subject}
                      <br/><br/>
                      Message: {textAlert.shortMessage}
                    </Popup>
                  </Table.Cell>
                </Table.Row>
              )
            }}
          />
        }
      </div>
    )
  }
}

export default TextAlertList
